<template>
  <div :class="['StatusPageItem', { 'is-over-limit': statusPage.is_over_limit }]">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col col-md-3">
          <router-link :to="editRoute" class="pl-1 font-weight-5 protect-long-text" :title="statusPage.title">
            {{ statusPage.title || 'Untitled' }}
          </router-link>
        </div>

        <div class="col col-md-4 status-monitors">
          {{ monitors }}
        </div>

        <div class="col col-md-4 public-link-wrapper">
          <div class="public-link">
            <a :href="publicLink"
               ref="publicLink"
               target="_blank">
              {{ publicLink.replace('https://','') }}
            </a>
            <div class="copy"
                  ref="copyToClipboard"
                  @click="copyToClipboard">copy</div>
            <div v-if="user.role !== role.Viewer && user.role !== role.Accountant" class="status-mobile">
              <status-page-actions
                :statusPage="statusPage"
                @copy-link="copyToClipboard"
                @delete="$emit('delete')"
              />
            </div>
          </div>
        </div>

        <div v-if="user.role !== role.Viewer && user.role !== role.Accountant" class="col col-md-1 d-flex justify-content-end status">
          <status-page-actions
            :statusPage="statusPage"
            @copy-link="copyToClipboard"
            @delete="$emit('delete')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusPageActions from './StatusPageItemActions.vue'
import tippy from 'tippy.js'
import { Role } from '@/_helpers/role.js'

export default {
  components: {
    StatusPageActions
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      copyToClipboardTippy: null,
      role: Role
    }
  },

  mounted () {
    this.copyToClipboardTippy = tippy(window.innerWidth > 900 ? this.$refs.copyToClipboard : this.$refs.publicLink, {
      content: 'Copied',
      arrow: false,
      placement: 'top',
      trigger: 'manual',
      animation: 'shift-away-extreme'
    })
  },

  methods: {
    async copyToClipboard () {
      await this.$copyText(this.publicLink)

      this.triggerTippy()
    },

    triggerTippy () {
      this.copyToClipboardTippy.show()

      setTimeout(() => {
        this.copyToClipboardTippy.hide()
      }, 1000)
    }
  },

  computed: {
    monitors () {
      let monitors = []
      this.statusPage.monitors.forEach(monitor => {
        let a = document.createElement('a')
        a.href = monitor.url

        monitors.push(a.hostname)
      })
      return monitors.join(' / ')
    },

    editRoute () {
      return {
        name: 'statusPages.update',
        params: {
          id: this.statusPage.id
        }
      }
    },

    publicLink () {
      const a = document.createElement('a')
      a.href = this.$router.resolve(this.publicRoute).href

      return a.href
    },

    publicRoute () {
      return {
        name: 'status',
        params: {
          slug: this.statusPage.slug
        }
      }
    },

    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .StatusPageItem {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 70px;
    //border: 1px solid rgb(212, 218, 231);
    box-shadow: 0 2px 4px rgba(25,34,29,0.05);
    border-radius: 6px;
    background-color: #fff;
    margin-bottom: 16px;
    transition: box-shadow 200ms ease 0s, border-color 200ms ease 0s;

    // Greyed-out state when isOverLimit is true
    &.is-over-limit {
      opacity: 0.5;
      background-color: #f0f0f0; // Light grey background (optional)
    }

    .protect-long-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .view-status-page {
      @include outlined-link;
    }

    .status {
      @media (max-width: 990px) {
        display: none !important;
      }

      &-mobile {
        display: none !important;
        @media (max-width: 990px) {
          display: flex !important;
        }
      }
    }

    .public-link {
      display: inline-flex;
      align-items: center;

      a {
        opacity: .6;

        &:hover {
          opacity: 1;
        }
      }

      @media (max-width: 990px) {
        width: 100%;
        justify-content: space-between;

        a {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-wrapper {
        width: 66.7%;

        @media (max-width: 990px) {
          flex: 0 0 58.33333% !important;
          max-width: 58.33333% !important;
        }

        @media (max-width: 767px) {
          flex: 0 0 66.33333% !important;
          max-width: 66.33333% !important;
        }
      }

      &:hover .copy {
        opacity: 1;
      }

      .copy {
        opacity: 0;
        margin-left: 10px;
        line-height: 1;
        padding: 5px 6px 4px;
        font-size: 12px;
        font-weight: 600;
        color: map-get($colors, gray-1);
        cursor: pointer;
        text-transform: uppercase;
        background: #f6f5f7;
        border-radius: 2px;
        line-height: 1;
        margin-bottom: 1px;
        transition: 0.2s all;

        @media (max-width: 990px) {
          display: none;
        }

        @media (max-width: 767px) {
          color: map-get($colors, purple-1);
        }

        &:hover {
          color: map-get($colors, purple-1);
        }
      }
    }

    .status-monitors {
      color: #1B1823;
      opacity: 0.6;
      white-space: nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
      flex: 1;

      @media (max-width: 1080px) {
          display: none;
      }
    }
  }
</style>
